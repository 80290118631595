import type { BigNumberish } from "@ethersproject/bignumber"

import { ENDPOINTS } from "common/constants/endpoints"
import { isProduction } from "common/helpers/environment"
import { fetcher } from "common/helpers/fetcher"
import type { AccountId, VoteType } from "query/graphql"
import { accountIdToChainId } from "web3/helpers/transformers"

type GenericAction = {
  from: string
  txHash: string
  contractAddress: string
}

type DelegateAction = {
  delegatee: string
  chainId: string
} & GenericAction

type CreateProposalAction = {
  proposalName?: string
  governanceId: AccountId
  metadata: {
    values: BigNumberish[]
    targets: string[]
    actions: string[]
  }
} & GenericAction

type VoteAction = {
  vote: VoteType
  reason?: string
  governanceId: AccountId
  proposalId: BigNumberish
} & GenericAction

type AddDAOAction = {
  from: string
  contractAddress: string
  registeredChainId: string
  daoName: string
  type: string
}

type AddDAOFunnelAction = {
  step: string
  from: string
}

type TallyAction = {
  type: string
  originatingAddress: string
  transactionHash?: string
  weight?: number
  governanceId?: string
  contractAddress: string
  proposalId?: BigNumberish
  metadata?: any
  chainId?: string
}

enum ACTION_TYPE {
  Delegation = "delegation",
  Vote = "vote",
  Proposal = "proposal",
  AddDao = "addDao",
  AddDaoFunnel = "addDaoFunnel",
}

type Values = {
  saveDirectDelegation: (tallyAction: DelegateAction) => Promise<void>
  saveSignatureDelegation: (tallyAction: DelegateAction) => Promise<void>
  saveCreateProposal: (tallyAction: CreateProposalAction) => Promise<void>
  saveVote: (tallyAction: VoteAction) => Promise<void>
  saveAddDAO: (tallyAction: AddDAOAction) => Promise<void>
  saveAddDAOFunnel: (tallyAction: AddDAOFunnelAction) => Promise<void>
}

export const useAnalytics = (): Values => {
  const postTallyAction = async (
    tallyAction: TallyAction,
    chainId: string,
  ): Promise<void> => {
    const apiKey = process.env.NEXT_PUBLIC_TALLY_API_KEY

    return fetcher.rest({
      method: "POST",
      endpoint: ENDPOINTS.analytics.action(),
      variables: {
        ...tallyAction,
        chainId,
      },
      headers: {
        "Api-Key": apiKey,
      },
    })
  }

  const saveDirectDelegation = async ({
    from,
    txHash,
    chainId,
    contractAddress,
    delegatee,
  }: DelegateAction): Promise<void> => {
    if (!isProduction) return

    const isSelfDelegation = from === delegatee

    return await postTallyAction(
      {
        type: ACTION_TYPE.Delegation,
        originatingAddress: from,
        transactionHash: txHash,
        contractAddress,
        chainId,
        metadata: {
          delegationType: "direct",
          delegatee,
          isSelfDelegation,
        },
      },
      chainId,
    )
  }

  const saveSignatureDelegation = async ({
    from,
    txHash,
    delegatee,
    chainId,
    contractAddress,
  }: DelegateAction): Promise<void> => {
    if (!isProduction) return

    const isSelfDelegation = from === delegatee

    return await postTallyAction(
      {
        type: ACTION_TYPE.Delegation,
        originatingAddress: from,
        transactionHash: txHash,
        chainId,
        contractAddress,
        metadata: {
          delegationType: "signature",
          delegatee,
          isSelfDelegation,
        },
      },
      chainId,
    )
  }

  const saveCreateProposal = async ({
    from,
    txHash,
    governanceId,
    contractAddress,
    proposalName,
    metadata,
  }: CreateProposalAction): Promise<void> => {
    if (!isProduction) return

    const chainId = governanceId && accountIdToChainId(governanceId)

    return await postTallyAction(
      {
        type: ACTION_TYPE.Proposal,
        originatingAddress: from,
        transactionHash: txHash,
        governanceId,
        contractAddress,
        chainId,
        metadata: { proposalName, ...metadata },
      },
      chainId,
    )
  }

  const saveVote = async ({
    from,
    txHash,
    contractAddress,
    proposalId,
    vote,
    governanceId,
    reason,
  }: VoteAction): Promise<void> => {
    if (!isProduction) return

    const chainId = governanceId && accountIdToChainId(governanceId)

    return await postTallyAction(
      {
        type: ACTION_TYPE.Vote,
        originatingAddress: from,
        transactionHash: txHash,
        governanceId,
        contractAddress,
        proposalId,
        chainId,
        metadata: { vote, reason },
      },
      chainId,
    )
  }

  const saveAddDAO = async ({
    from,
    contractAddress,
    registeredChainId,
    daoName,
    type,
  }: AddDAOAction): Promise<void> => {
    if (!isProduction) return

    return await postTallyAction(
      {
        type: ACTION_TYPE.AddDao,
        originatingAddress: from,
        contractAddress,
        chainId: registeredChainId,
        metadata: {
          daoName,
          type,
        },
      },
      registeredChainId,
    )
  }

  const saveAddDAOFunnel = async ({
    from,
    step,
  }: AddDAOFunnelAction): Promise<void> => {
    if (!isProduction) return

    return await postTallyAction(
      {
        type: ACTION_TYPE.AddDaoFunnel,
        originatingAddress: from,
        contractAddress: "",
        chainId: "",
        metadata: {
          step,
          from,
        },
      },
      "",
    )
  }

  return {
    saveDirectDelegation,
    saveSignatureDelegation,
    saveCreateProposal,
    saveVote,
    saveAddDAO,
    saveAddDAOFunnel,
  }
}
