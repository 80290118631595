import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from "@chakra-ui/react"
import type { FC } from "react"
import React from "react"

import { useModals } from "common/hooks/useModals"

type Props = {
  title?: string
  body: string
  onConfirm?: () => void
  onCancel?: () => void
  confirmLabelBtn?: string
  cancelLabelBtn?: string
}
const ConfirmDialog: FC<Props> = ({
  title,
  body,
  onConfirm,
  onCancel,
  confirmLabelBtn = "Confirm",
  cancelLabelBtn = "Cancel",
}) => {
  const cancelRef = React.useRef(null)
  const { confirmDialogModal } = useModals()

  const handleConfirmClick = () => {
    onConfirm?.()
    confirmDialogModal.onClose()
  }
  const handleCancelClick = () => {
    onCancel?.()
    confirmDialogModal.onClose()
  }

  return (
    <AlertDialog
      isCentered
      isOpen={confirmDialogModal.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleCancelClick}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          {title ? (
            <AlertDialogHeader fontSize="lg">{title}</AlertDialogHeader>
          ) : null}
          <AlertDialogCloseButton />

          {body ? (
            <AlertDialogBody mt={title ? 0 : 8}>
              <Text>{body}</Text>
            </AlertDialogBody>
          ) : null}

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCancelClick}>
              {cancelLabelBtn}
            </Button>
            <Button ml={3} variant="primary" onClick={handleConfirmClick}>
              {confirmLabelBtn}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ConfirmDialog
