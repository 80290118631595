import type { BigNumberish } from "@ethersproject/bignumber"

import { API_ROUTES } from "common/constants/routes"
import { fetcher } from "common/helpers/fetcher"
import { useToast } from "common/hooks/useToast"

type SubmitDelegationTransactionParams = {
  organizationId: string
  tokenId: string
  address: string
  delegateeAddress: string
  contractAddress: string
  contractName: string
  data: string
  gasLimit: number
  chainId: string
  signature: string
  nonce: number
  expiry: number
}

type SubmitVotingTransactionParams = {
  governanceId: string
  address: string
  proposalId: BigNumberish
  support: BigNumberish
  contractAddress: string
  contractName: string
  data: string
  gasLimit: number
  chainId: string
  signature: string
}

export type RelayerTransactionResponse = {
  tx?: {
    hash: string
    gasPrice: number
    gasLimit: number
    createdAt: string
  }
}

export const useRelayer = () => {
  const { toast } = useToast()

  const submitDelegateTransaction = ({
    organizationId,
    tokenId,
    address,
    delegateeAddress,
    contractAddress,
    contractName,
    data,
    gasLimit,
    chainId,
    signature,
    nonce,
    expiry,
  }: SubmitDelegationTransactionParams) => {
    return fetcher
      .rest({
        endpoint: API_ROUTES.txRelayer.delegations.submit(),
        method: "POST",
        variables: {
          organizationId,
          tokenId,
          accountAddress: address,
          delegatee: delegateeAddress,
          to: contractAddress,
          contractName,
          data,
          gasLimit,
          chainId,
          signature,
          nonce,
          expiry,
        },
        onError: (error) => {
          toast({
            status: "error",
            title: "Transaction failed",
            description: error.context.error,
            duration: 5000,
          })
        },
      })
      .then((data) => {
        if (!data) {
          return
        }

        toast({
          status: "success",
          title: "Transaction sent",
          description: `The transaction will be executed soon!`,
          duration: 5000,
        })

        return data
      })
  }

  const submitCastVoteTransaction = ({
    governanceId,
    address,
    proposalId,
    support,
    contractName,
    contractAddress,
    data,
    gasLimit,
    chainId,
    signature,
  }: SubmitVotingTransactionParams): Promise<
    RelayerTransactionResponse | undefined
  > => {
    return fetcher
      .rest({
        endpoint: API_ROUTES.txRelayer.voting.submit(),
        method: "POST",
        variables: {
          governanceId,
          accountAddress: address,
          contractAddress,
          proposalId,
          support,
          contractName,
          data,
          gasLimit,
          chainId,
          signature,
        },
        onError: (error) => {
          toast({
            status: "error",
            title: "Transaction failed",
            description: error.context.error,
            duration: 5000,
          })
        },
      })
      .then((data) => {
        if (!data) {
          return undefined
        }

        toast({
          status: "success",
          title: "Transaction sent",
          description: `The transaction will be executed soon!`,
          duration: 5000,
        })

        return data
      })
  }

  return {
    submitCastVoteTransaction,
    submitDelegateTransaction,
  }
}
