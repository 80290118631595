import { useMemo } from "react"
import type { Address } from "wagmi"
import { useAccount } from "wagmi"

import { useMe } from "user/providers/MeProvider"

export const useAddress = (): Address | undefined => {
  const { address: walletAddress } = useAccount()
  const me = useMe()

  const signerAddress = useMemo(() => {
    // me.address works for both EOA or Safe (if user switch to some specific Safe)
    if (me?.address) {
      return me.address
    }

    // Otherwise, always use address from wallet
    return walletAddress
  }, [me?.address, walletAddress])

  return signerAddress ? (signerAddress as Address) : undefined
}
