import {
  getChainIdParams,
  isValidChainId,
  isValidChainReference,
} from "web3/helpers/chainId"
import type { ChainId } from "query/graphql"

export enum UseCorrectChainErrors {
  Nochainid = "NO_CHAIN_ID",
  Invalidchainid = "INVALID_CHAIN_ID",
  Nochainreference = "NO_CHAIN_REFERENCE",
  Invalidchainreference = "INVALID_CHAIN_REFERENCE",
}

type Values = {
  error?: UseCorrectChainErrors
  isCorrectChain: boolean
}

type Props = {
  chainId: ChainId | undefined
  chainReference: number | undefined
}

export const getCorrectChain = ({ chainId, chainReference }: Props): Values => {
  if (chainId === undefined) {
    return {
      isCorrectChain: false,
      error: UseCorrectChainErrors.Nochainid,
    }
  }

  if (chainReference === undefined) {
    return {
      isCorrectChain: false,
      error: UseCorrectChainErrors.Nochainreference,
    }
  }

  if (!isValidChainId(chainId)) {
    return {
      isCorrectChain: false,
      error: UseCorrectChainErrors.Invalidchainid,
    }
  }

  if (!isValidChainReference(chainReference)) {
    return {
      isCorrectChain: false,
      error: UseCorrectChainErrors.Invalidchainreference,
    }
  }

  const { reference } = getChainIdParams(chainId)

  return {
    isCorrectChain: Number(reference) === chainReference,
    error: undefined,
  }
}
