import type { BigNumberish } from "ethers"

import { GovernorType } from "query/graphql"

export const getSignDomain = ({
  contractName,
  chainId,
  contractAddress,
  governorType,
}: {
  contractName: string
  chainId: number | undefined
  contractAddress: string
  governorType: GovernorType | null
}) => {
  // Use "version" only if OZ
  if (governorType === GovernorType.Openzeppelingovernor) {
    const domain = {
      name: contractName,
      version: "1",
      chainId,
      verifyingContract: contractAddress as `0x${string}`,
    } as const

    return domain
  }

  // No "version" in other cases
  const domain = {
    name: contractName,
    chainId,
    verifyingContract: contractAddress as `0x${string}`,
  } as const

  return domain
}

export const getSignTypes = ({
  governorType,
}: {
  governorType: GovernorType | null
}) => {
  // Use "version" only if OZ
  if (governorType === GovernorType.Openzeppelingovernor) {
    const types = {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Ballot: [
        { name: "proposalId", type: "uint256" },
        { name: "support", type: "uint8" },
      ],
    }

    return types
  }

  // No "version" in other cases
  const types = {
    EIP712Domain: [
      { name: "name", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ],
    Ballot: [
      { name: "proposalId", type: "uint256" },
      { name: "support", type: "uint8" },
    ],
  }

  return types
}

export const getSignValue = ({
  proposalId,
  support,
}: {
  proposalId: BigNumberish
  support: number
}) => {
  const value = {
    proposalId,
    support,
  }

  return value
}
