import React from "react"
import { createIcon } from "@chakra-ui/icons"

const ArrowRight = createIcon({
  displayName: "ArrowRight",
  viewBox: "0 0 13 12",
  path: (
    <path
      d="M7.71875 1.0708L12.5312 5.66455C12.668 5.80127 12.75 5.96533 12.75 6.15674C12.75 6.3208 12.668 6.48486 12.5312 6.62158L7.71875 11.2153C7.47266 11.4614 7.03516 11.4614 6.78906 11.188C6.54297 10.9419 6.54297 10.5044 6.81641 10.2583L10.4531 6.81299H1.15625C0.773438 6.81299 0.5 6.51221 0.5 6.15674C0.5 5.77393 0.773438 5.50049 1.15625 5.50049H10.4531L6.81641 2.02783C6.54297 1.78174 6.54297 1.34424 6.78906 1.09814C7.03516 0.824707 7.44531 0.824707 7.71875 1.0708Z"
      fill="inherit"
    />
  ),
})

export default ArrowRight
