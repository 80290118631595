import { useNetwork } from "wagmi"
import { useMemo } from "react"

import { chainToChainReference } from "web3/helpers/transformers"

export function useChainReference() {
  const network = useNetwork()
  const chainReference = useMemo(
    () => chainToChainReference(network.chain),
    [network],
  )

  return chainReference
}
