import type { FC, ReactNode } from "react"
import type { AlertStatus } from "@chakra-ui/react"
import { Alert, AlertIcon } from "@chakra-ui/react"

type Props = {
  status: AlertStatus
  children: ReactNode
  isDisplayingIcon?: boolean
}
const AlertMessage: FC<Props> = ({
  status,
  isDisplayingIcon = true,
  children,
}) => {
  return (
    <Alert fontSize="sm" mb={2} status={status}>
      {isDisplayingIcon ? <AlertIcon /> : null}
      {children}
    </Alert>
  )
}

export default AlertMessage
