import type { FC } from "react"
import React from "react"
import type { ContainerProps } from "@chakra-ui/react"
import { Flex, Container as ChakraContainer } from "@chakra-ui/react"

import { AwaitRender } from "common/components/AwaitRender"
import { BlockNumberProvider } from "web3/providers/BlockNumberProvider"

const MainLayoutContainer: FC<ContainerProps> = ({
  children,
  ...containerProps
}) => {
  return (
    <AwaitRender loader={<Flex flex={1} w="full" />} ms={200}>
      <BlockNumberProvider>
        <ChakraContainer
          centerContent
          alignItems="stretch"
          as="main"
          display="flex"
          flex={1}
          flexDir="column"
          maxW="6xl"
          pt={4}
          px={{ base: 4, lg: 0 }}
          {...containerProps}
        >
          {children}
        </ChakraContainer>
      </BlockNumberProvider>
    </AwaitRender>
  )
}

export default MainLayoutContainer
