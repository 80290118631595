import { useState } from "react"
import { useToast } from "@chakra-ui/react"

import { MetamaskErrors } from "web3/types/metamask"
import { getChainIdParams } from "web3/helpers/chainId"
import { removeTrailingSlashes } from "common/helpers/utils"
import { getChainByChainId } from "web3/helpers/findChain"

type Values = {
  addNetwork: (governanceChainId: string) => void
  isRejected: boolean
}

const useAddNetwork = (): Values => {
  const [isRejected, setIsRejected] = useState(false)
  const toast = useToast()

  const addNetwork = (governanceChainId: string) => {
    const { reference } = getChainIdParams(governanceChainId)
    const hexReference = reference.toString(16)

    const foundChain = getChainByChainId(governanceChainId)

    if (!window?.ethereum) {
      toast({
        status: "error",
        title: "Error",
        description: "Metamask is not installed. Please, install Metamask",
      })
    }

    const provider = window.ethereum

    const rpcUrl =
      foundChain &&
      foundChain.envRPCArg &&
      process.env[`${foundChain.envRPCArg}`]
        ? (process.env[`${foundChain.envRPCArg}_PUBLIC`] as string) ??
          (process.env[foundChain.envRPCArg] as string)
        : ""

    provider
      .send("wallet_addEthereumChain", [
        {
          chainId: `0x${hexReference}`,
          rpcUrls: [rpcUrl],
          chainName: foundChain?.mediumName,
          nativeCurrency: {
            name: foundChain?.nativeCurrency.name,
            symbol: foundChain?.nativeCurrency.symbol,
            decimals: foundChain?.nativeCurrency.decimals,
          },
          blockExplorerUrls: [
            `${removeTrailingSlashes(foundChain?.blockExplorer?.url)}/address/`,
          ],
        },
      ])
      .catch((error: any) => {
        if (error.code === MetamaskErrors.Rejection) {
          setIsRejected(true)

          return
        }

        if (error.code === 4902) {
          addNetwork(governanceChainId)
          toast({
            status: "warning",
            title: "Warning",
            description: "This network doesn't exist in your wallet",
          })
        } else {
          toast({
            status: "error",
            title: "Error",
            description:
              "We couldn't switch to this network. Please, try to do it manually",
          })
        }
      })
  }

  return {
    addNetwork,
    isRejected,
  }
}

export { useAddNetwork }
